import { Link } from 'gatsby';
import React, { Fragment } from 'react';
import { Direction } from '../../../globals';
import Group from '../../layout/Group/Group';
import Block from '../Cards/Block';
import '../Cards/Card.scss';

interface PrevNextProps {
    prevArticle?: any;
    nextArticle?: any;
}

const CardPrevNext: React.FC<PrevNextProps> = (props: PrevNextProps) => {
    return (
      <Fragment>
        <Group size={4}>
            <Block direction={Direction.horizontal}>
              {(props.prevArticle !== null && props.prevArticle !== undefined) && (
                <Link
                  className="card-block card-block--horizontal"
                  to={`/${props.prevArticle?.suburl}`}
                >
                <Block direction={Direction.vertical} className="card-block--flex">
                  <span className='card-block--label-end'>Anterior ←</span><br/>
                  <span className="card-block--item card-block--label-end">{props.prevArticle?.title}</span>
                </Block>
                </Link>
              )}
            </Block>

            <Block direction={Direction.horizontal}>
              {(props.nextArticle !== null && props.nextArticle !== undefined) && (
                <Link
                  className="card-block  card-block--horizontal"
                  to={`/${props.nextArticle?.suburl}`}
                >
                  <Block direction={Direction.vertical} className="card-block--flex">
                    <span className='card-block--label-start'>→ Próximo</span><br/>
                    <span className="card-block--item card-block--label-start">{props.nextArticle?.title}</span>
                  </Block>
                </Link>
              )}
            </Block>
        </Group>
      </Fragment>
    );
};

export default CardPrevNext;
