import React from 'react';
import './shares.scss';
import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    // Icones
    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import Container from '../../layout/Container/Container';

interface ShareButtonsProps {
    currentArticle?: any;
}

const ShareButtons: React.FC<ShareButtonsProps> = (props: ShareButtonsProps) => {
    const article = props.currentArticle;
    const shareUrl = 'https://soliditynapratica.com/'+article.suburl;
    return (
        <div className="Share__container">
            <div className="Share__some-network">
                <FacebookShareButton
                    url={shareUrl}
                    quote={article.title}
                    windowWidth={800}
                    windowHeight={600}
                    className="Share__some-network__share-button"
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            </div>
            <div className="Share__some-network">
                <LinkedinShareButton 
                    title={article.title}
                    summary={article.description}
                    url={'https://soliditynapratica.com/'} 
                    windowWidth={800}
                    windowHeight={600}
                    className="Share__some-network__share-button"
                >
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </div>
            <div className="Share__some-network">
                <TelegramShareButton
                    url={shareUrl}
                    title={article.title}
                    windowWidth={800}
                    windowHeight={600}
                    className="Share__some-network__share-button"
                >
                    <TelegramIcon size={32} round />
                </TelegramShareButton>
            </div>
            <div className="Share__some-network">
                <TwitterShareButton
                    url={shareUrl}
                    title={article.title}
                    windowWidth={800}
                    windowHeight={600}
                    className="Share__some-network__share-button"
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
            </div>
            <div className="Share__some-network">
                <WhatsappShareButton
                    url={shareUrl}
                    title={article.title}
                    separator=":: "
                    windowWidth={800}
                    windowHeight={600}
                    className="Share__some-network__share-button"
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </div>
            <div className="Share__some-network">
                <RedditShareButton
                    url={shareUrl}
                    title={article.title}
                    windowWidth={800}
                    windowHeight={600}
                    className="Share__some-network__share-button"
                >
                    <RedditIcon size={32} round />
                </RedditShareButton>
            </div>
        </div>
    );
};

export default ShareButtons;
